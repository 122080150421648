import { Button, ErrorBoundary, Popup, Theme, themeOptions } from '@commonsku/styles';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { createAddItem, createAddItemCancel } from '../actions';

import { closePopup } from '../actions/popup';
import { BASE_ZINDEX } from '../popup-factory';
import { createGlobalStyle } from './helpers';

import SelectSku from './SelectSku';

const CURRENCY_MAP = {
  'US': 'USD',
  'CA': 'CAD'
};

const getFOB = (product, requestedCurrency) => {
  const availableFobs = product.fobs || [];
  return availableFobs.filter(f => f.supported_currencies.includes(requestedCurrency))[0] || availableFobs[0] || {};
};

const getCurrency = (product, requestedCurrency) => {
  if (!!product.indexed_costs[requestedCurrency]) {
    return requestedCurrency;
  }
  const FOB = getFOB(product, requestedCurrency);
  if (!!product.indexed_costs[CURRENCY_MAP[FOB.country]]) {
    return CURRENCY_MAP[FOB.country];
  }
  return product.currency_id || (!!product.indexed_costs['USD'] ? 'USD' : Object.keys(product.indexed_costs)[0] || 'USD');
};

const GlobalStyle = createGlobalStyle();

class SelectReferenceProductPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      referenceType: 'selected',
      comboType: 'reference',
      skus: []
    };

    ['onClosePopup', 'onAddProduct'].forEach(m => this[m] = this[m].bind(this));
  }

  onClosePopup(e) {
    this.props.dispatch(closePopup());
    this.props.dispatch(createAddItemCancel());
  }

  onAddProduct(fob_id, currency_id, skus = null) {
    const { dispatch, order_id, parent_type, product, product_source, target_type, display_order, success_callback } = this.props;
    const ext_product_id = product.ext_product_id;
    const { comboType, referenceType } = this.state;
    const result = dispatch(createAddItem(order_id, parent_type, ext_product_id, product_source, target_type, display_order, fob_id, currency_id, 'selected' === referenceType ? skus : 'cheapest', null, null, comboType));
    dispatch(closePopup());
    if (success_callback) {
      result.then(success_callback);
    }
  }

  render() {
    const { product, index } = this.props;
    const { referenceType, comboType } = this.state;

    const popupStyle = {
      overflow: 'visible',
      height: 'initial',
      display: 'block',
      zIndex: BASE_ZINDEX + index
    };

    const fob = getFOB(product, this.props.currency_id);
    const currency_id = getCurrency(product, this.props.currency_id);

    const isDisabled = 'selected' === referenceType && !this.state.skus[0];

    return (
      <Theme theme={themeOptions}>
        <GlobalStyle theme={themeOptions} />
        <ErrorBoundary>
          <Popup
            title={`Add ${product.product_name}`}
            onClose={this.handleClose}
            zIndex={BASE_ZINDEX + index}
            overlayZIndex={BASE_ZINDEX + index}
            controls={<>
              <Button style={{ marginRight: 10, }} variant={'secondary'} onClick={this.onClosePopup}>Cancel</Button>
              <Button
                variant={isDisabled ? 'disabled' :'cta'}
                disabled={isDisabled}
                onClick={() => this.onAddProduct(fob.fob_id, currency_id, this.state.skus)}
              >Add Product</Button>
            </>}
          >
            <SelectSku
              product={product}
              product_id={product.product_id}
              currency_id={currency_id}
              onSkusSelected={skus => this.setState({ skus })}
              selectable={false}
              multi={false}
              maxHeight="24rem"
              priceFilter={true}
            />
          </Popup>
        </ErrorBoundary>
      </Theme>
    );
  }
}

export default connect()(SelectReferenceProductPopup);
